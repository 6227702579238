import React, { useState, useEffect, useRef } from "react";
import sales from "../Sales";
import Navbar from "../Navbar/Navbar";
import ReactSpeedometer from "react-d3-speedometer";
import Plot from "react-plotly.js";
import "./Dashboard.css";
import "./ChartStyles.css";
import Logo from "./techdogOlogo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal, faWeight } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";

const getResponsiveDimensions = () => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const width = screenWidth > 1920 ? screenWidth * 0.4 : 250;
  const height = screenHeight > 1080 ? screenHeight * 0.3 : 225;

  return { width, height };
};

const Dashboard = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const { month, year } = useParams();

  const now = dayjs(`${year}-${month}`).locale("nl").format("MMMM");
  const [chartDimensions, setChartDimensions] = useState({
    width: 250,
    height: 225,
  });
  const [teamTarget, setTeamTarget] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  const [deals, setDeals] = useState([]);
  const [existantDeals, setExistantDeals] = useState([]);
  const [newDeals, setNewDeals] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [rankList, setRankList] = useState([]);
  const [soldServices, setSoldServices] = useState([]);
  const [xMonthRankList, setXMonthRankList] = useState([]);
  const [yMonthRankList, setYMonthRankList] = useState([]);
  const [yearRankList, setYearRankList] = useState([]);

  function checkIfIntegerAddComma(number) {
    if (Number.isInteger(number)) {
      return number.toString().replace(".", ",");
    } else {
      return number.toFixed(2).toString().replace(".", ",");
    }
  }

  async function fetchAll(month, year, reload) {
    try {
      if (!reload) {
        setIsLoading(true);
      }

      const monthNumber = parseInt(month, 10);
      const yearNumber = parseInt(year, 10);

      let prevMonth = 0;
      let prevYear = 0;

      if (monthNumber === 1) {
        prevMonth = 12;
        prevYear = yearNumber - 1;
      } else {
        prevMonth = monthNumber - 1;
        prevYear = yearNumber;
      }

      const [
        rankListResponse,
        teamTargetResponse,
        mostSoldProductsResponse,
        yearRankListResponse,
        bestSellerResponse,
        dealsResponse,
        existantDealsResponse,
        newDealsResponse,
      ] = await Promise.all([
        sales.get(`rankList?month=${month}&year=${year}`),
        sales.get(`teamTarget?month=${month}&year=${year}`),
        sales.get(`mostSoldProducts?month=${month}&year=${year}`),
        sales.get(`yearRankList?month=${month}&year=${year}`),
        sales.get(`bestSeller?month=${prevMonth}&year=${prevYear}`),
        sales.get(`getDeals?month=${prevMonth}&year=${prevYear}`),
        sales.get(`getDeals?month=${month}&year=${year}&status=0`),
        sales.get(`getDeals?month=${month}&year=${year}&status=1`),
      ]);

      setSoldServices(mostSoldProductsResponse.data);
      setTeamTarget(teamTargetResponse.data);
      setBestSeller(bestSellerResponse.data);
      setDeals(dealsResponse.data);
      setExistantDeals(existantDealsResponse.data);
      setNewDeals(newDealsResponse.data);
      setYearRankList(yearRankListResponse.data);

      const rankListData = rankListResponse.data[0];
      const rankListAmount = rankListResponse.data[1];
      setRankList(rankListData);
      setAmount(rankListAmount);

      let names = [];
      let totalPrices = [];
      rankListData.forEach((user) => {
        totalPrices.push(user.totalPrice);
        names.push(user.name);
      });
      setXMonthRankList(names);
      setYMonthRankList(totalPrices);

      if (teamTargetResponse.data.amount < rankListResponse.data[1]) {
        setTotalPrice(teamTargetResponse.data.amount);
      } else {
        setTotalPrice(rankListResponse.data[1]);
      }
      setIsLoading(false);
    } catch (error) {
      console.warn(error.response);
      cookies.remove("token", { path: "/" });
      navigate("/");
    }
  }

  const MINUTE_MS = 60000;

  useEffect(() => {
    if (cookies.get("token")) {
      fetchAll(month, year, false);

      const interval = setInterval(() => {
        fetchAll(month, year, true);
      }, MINUTE_MS);

      return () => clearInterval(interval);
    } else {
      navigate("/");
    }
  }, [month, year]);

  const SpeedoMeterBox = () => {
    const containerRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [dimensions, setDimensions] = useState({
      width: 375, // base width
      height: 200, // base height
    });

    useEffect(() => {
      let resizeTimer = null;

      const updateScale = () => {
        if (containerRef.current) {
          const parent = containerRef.current.parentElement;
          const parentRect = parent.getBoundingClientRect();
          const scaleX = parentRect.width / dimensions.width;
          const scaleY = parentRect.height / dimensions.height;
          const newScale = Math.min(scaleX, scaleY) * 0.9;

          setScale(newScale);
        }
      };

      const handleResize = () => {
        if (resizeTimer) {
          window.clearTimeout(resizeTimer);
        }
        resizeTimer = window.setTimeout(() => {
          updateScale();
        }, 250);
      };

      // Initial scale
      updateScale();

      // Window resize listener
      window.addEventListener("resize", handleResize);

      // ResizeObserver for container changes
      const resizeObserver = new ResizeObserver(handleResize);

      if (containerRef.current?.parentElement) {
        resizeObserver.observe(containerRef.current.parentElement);
      }

      // Cleanup both resize listener and observer
      return () => {
        window.removeEventListener("resize", handleResize);
        resizeObserver.disconnect();
        if (resizeTimer) {
          window.clearTimeout(resizeTimer);
        }
      };
    }, [dimensions]);

    function deleteAllCookies() {
      document.cookie.split(";").forEach(function (cookie) {
        document.cookie = cookie;
        if (cookie.includes("token")) {
        } else {
          document.cookie = cookie
            .replace(/^ +/, "")
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              "=;expires=" + new Date(0).toUTCString() + ";path=/"
            );
        }
      });
    }

    function resetCacheAndCookies() {

      const now = dayjs();
      console.log(now);
      if (now.hour() === 0 && now.minute() === 0 && now.second() === 0) {
        navigate(`/dashboard/${now.month() + 1}/${now.year()}`);
      }

      if (
        (now.minute() === 30 && now.second() === 0) ||
        (now.minute() === 0 && now.second() === 0)
      ) {
        deleteAllCookies();
      }
    }

    useEffect(() => {
      // checks every second if the time is correct in resetCacheAndCookies
      resetCacheAndCookies();
      const interval = setInterval(() => {
        resetCacheAndCookies();
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    function adjustTickLabels() {
      const tickTexts = document.querySelectorAll(
        ".xaxislayer-above .xtick text"
      );

      tickTexts.forEach((text) => {
        // Get the current transform attribute
        const currentTransform = text.getAttribute("transform");

        // Extract the x value using regex - finds number after "translate(" and before comma
        const match = currentTransform.match(/translate\((\d+\.?\d*),/);

        if (match) {
          // match[1] contains just the x value
          const xValue = match[1];
          // Create new transform with same x but new y value (5)
          text.setAttribute("transform", `translate(${xValue},7.5)`);
        }

        // Add responsive font sizing to prevent overlap
        text.style.fontSize = "clamp(8px, 0.7vw, 12px)";
        // Ensure text stays on one line
        text.style.whiteSpace = "nowrap";
        // Add slight letter spacing compression for better fit
        text.style.letterSpacing = "-0.02em";
      });
    }

    // Set up observer to handle resizing
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(adjustTickLabels, 100);
    });

    // Start observing the plot container
    const plotContainer = document.querySelector(".nsewdrag");
    if (plotContainer) {
      resizeObserver.observe(plotContainer);
    }

    // Initial adjustment
    setTimeout(adjustTickLabels, 100);

    return (
      <div
        ref={containerRef}
        className="d-flex align-items-center justify-content-center w-100 h-100"
        style={{
          minHeight: "200px",
          transform: `scale(${scale})`,
          transition: "transform 0.1s ease-in-out",
        }}
      >
        <ReactSpeedometer
          width={dimensions.width}
          height={dimensions.height}
          maxValue={teamTarget.amount}
          needleHeightRatio={0.7}
          segments={6}
          needleColor={"#B4B4B4"}
          labelFontSize={"0"}
          startColor={"#2684C4"}
          endColor={"#1C262F"}
          value={teamTarget.amount ? totalPrice : 0}
          textColor={textColor}
          currentValueText=""
          valueTextFontSize={0}
        />
      </div>
    );
  };

  const textColor = "#ffffff";

  function capitalize(month) {
    return month.charAt(0).toUpperCase() + month.slice(1);
  }

  function nameLimiter(text) {
    return text.length >= 23 ? `${text.slice(0, 23)}...` : text;
  }

  const roundedValues = yMonthRankList.map((value) => {
    const roundedValue = value.toFixed(2);
    return roundedValue.endsWith(".00") ? value.toFixed(0) : roundedValue; // if value ends with .00, remove decimals
  });
  const ranklistColors = [
    "#2685C7",
    "#267BB4",
    "#306285",
    "#2C485D",
    "#202F3B",
  ];
  const monthRankListBox = [
    {
      x: xMonthRankList,
      y: yMonthRankList,
      type: "bar",
      textposition: "outside",
      cliponaxis: false,
      text: roundedValues.map((v) => `<b>€${v}</b>`),
      marker: {
        color: yMonthRankList.map(
          (_, index) => ranklistColors[index % ranklistColors.length]
        ),
      },
      textfont: {
        size: 22,
        color: "#2D4C67",
        family: "proxima-nova, sans-serif",
      },
      preserveAspectRatio: "none",
    },
  ];

  const rankListBoxLayout = {
    "max-height": 100,
    title: {
      text: `<span style="font-weight: bold; font-family: 'proxima-nova', sans-serif">RANGLIJST VAN <span style="color: #2884C5;">${now.toUpperCase()}</span></span>`,
      font: {
        size: 24,
        color: "#6C6C6B",
      },
    },
    xaxis: {
      title: "",
      tickangle: 0,
      tickfont: {
        size: 11,
      },
      automargin: false,
      showgrid: false,
      fixedrange: true,
    },
    margin: {
      b: 30,
      l: 60,
      r: 20,
      t: 90,
    },
    autosize: true,
    responsive: true,
    showlegend: false,
    yaxis: {
      showgrid: false,
      range: undefined,
      fixedrange: true,
      showticklabels: false,
      visible: false,
    },
  };

  const maxDataRanklist = Math.max(...yearRankList.map((d) => d.totalPrice));
  const chartHeight = 225;
  const barHeightRanklist = chartHeight / yearRankList.length + 20;
  let barPadding = 0.5;

  if (yearRankList.length <= 5 || soldServices.length <= 5) {
    barPadding = 1.5;
  }

  const getChartStyles = () => ({
    nameText: {
      fontWeight: "bold",
      fontSize: "clamp(0.7rem, 0.25vw, 5.0rem)",
      textAnchor: "start",
      fill: "#2884C5",
    },
    monthText: {
      fontWeight: "bold",
      fontSize: "clamp(0.7rem, 0.25vw, 5.0rem)",
      textAnchor: "start",
      fill: "#144361",
    },
    valueText: {
      fontWeight: "bold",
      fontSize: "clamp(0.6rem, 0.25vw, 5.0rem)",
      textAnchor: "start",
      fill: "#6C6C6B",
    },
  });

  const getChartStylesMostSold = () => ({
    nameText: {
      fontWeight: "bold",
      fontSize: "clamp(1.6rem, 0.25vw, 5.0rem)",
      textAnchor: "start",
      fill: "#144361",
    },
    valueText: {
      fontWeight: "bold",
      fontSize: "clamp(1.5rem, 0.25vw, 5.0rem)",
      textAnchor: "start",
      fill: "#6C6C6B",
    },
  });

  const RanklistChart = () => {
    const styles = getChartStyles();
    const maxBarWidth = 220;
    const startX = 75;
    const DEFAULT_WIDTH = 5; // Changed to 5px minimum as requested

    return (
      <svg width="100%" height="100%" viewBox="0 0 300 480">
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              style={{ stopColor: "#14476a", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "rgba(40,132,197,1)", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        {yearRankList.map((d, i) => {
          const proportion = d.totalPrice / maxDataRanklist;
          const scaledWidth = proportion * maxBarWidth;
          const barWidth = Math.max(DEFAULT_WIDTH, scaledWidth); // Ensure minimum width of 5px
          const month = capitalize(
            dayjs(d.date, "M-YYYY").locale("nl").format("MMM")
          );
          return (
            <g key={i}>
              <text
                x="0"
                y={
                  i * (barHeightRanklist + barPadding) +
                  barHeightRanklist / 2 -
                  9
                }
                alignmentBaseline="middle"
                style={styles.monthText}
              >
                {month}
              </text>
              <text
                x="25"
                y={
                  i * (barHeightRanklist + barPadding) +
                  barHeightRanklist / 2 -
                  9
                }
                alignmentBaseline="middle"
                style={styles.nameText}
              >
                {nameLimiter(d.name)}
              </text>
              <text
                x="0"
                y={
                  i * (barHeightRanklist + barPadding) + barHeightRanklist / 1.8
                }
                alignmentBaseline="middle"
                style={styles.valueText}
              >
                €{checkIfIntegerAddComma(d.totalPrice)}
              </text>
              <rect
                x={startX}
                y={i * (barHeightRanklist + barPadding) + 5}
                width={maxBarWidth}
                height={barHeightRanklist / 4}
                fill="#F5F5F5"
                // border-radius
                rx="5"
                ry="5"
              />
              <rect
                x={startX}
                y={i * (barHeightRanklist + barPadding) + barPadding / 2}
                width={barWidth}
                height={barHeightRanklist / 1.8}
                fill="url(#gradient1)"
                // border-radius
                rx="5"
                ry="5"
              />
            </g>
          );
        })}
      </svg>
    );
  };

  const MostSoldProductsChart = () => {
    const styles = getChartStylesMostSold();
    const marginLeft = 10;
    const marginRight = 10;

    const chartWidth = 650;

    // Balkhoogte & verticale padding
    const barHeightServices = 15;
    const barPadding = 90; // verhoogd voor meer verticale ruimte
    const barSpacing = barHeightServices + barPadding + 20;

    const maxBarWidth = chartWidth - (marginLeft + marginRight);
    const DEFAULT_WIDTH = 5;

    // Hoogste waarde (voor schaalberekening)
    const maxValue = Math.max(...soldServices.map((d) => d.totalPrice));

    const scrollStyle = {
      overflowY: soldServices.length >= 7 ? "auto" : "hidden",
    };

    return (
      <div className="Scroll mt-2" style={scrollStyle}>
        <svg
          width="450"
          height="900"
          viewBox={`0 0 650 1300`}
          preserveAspectRatio="xMidYMid meet"
        >
          <defs>
            <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop
                offset="0%"
                style={{ stopColor: "#14476a", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "rgba(40,132,197,1)", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>

          {soldServices.map((d, i) => {
            // Breedte van de balk op basis van de hoogste waarde
            const proportion = maxValue ? d.totalPrice / maxValue : 0;
            const scaledWidth = proportion * maxBarWidth;
            const barWidth = Math.max(DEFAULT_WIDTH, scaledWidth);

            return (
              <g key={i} transform={`translate(0, ${i * barSpacing})`}>
                {/* Tekst boven de balk */}
                <text
                  x={marginLeft}
                  y={20}
                  alignmentBaseline="hanging"
                  style={styles.nameText}
                >
                  {nameLimiter(d.name)}
                </text>
                <text
                  x={marginLeft}
                  y={50}
                  alignmentBaseline="hanging"
                  style={styles.valueText}
                >
                  €{checkIfIntegerAddComma(d.totalPrice)}
                </text>

                {/* Achtergrondbalk */}
                <rect
                  x={marginLeft}
                  y={105}
                  width={maxBarWidth}
                  height={barHeightServices}
                  fill="#F5F5F5"
                  rx="5"
                  ry="5"
                />

                {/* Gekleurde value-balk*/}
                <rect
                  x={marginLeft}
                  y={90}
                  width={barWidth}
                  height={barHeightServices * 2.5}
                  fill="url(#gradient1)"
                  rx="5"
                  ry="5"
                />
              </g>
            );
          })}
        </svg>
      </div>
    );
  };

  return (
    <div className="bodyDashboard flex flex-col">
      <Navbar />
      {!isLoading ? (
        <div className="box-container">
          <div className="row p-3">
            <div className="col-12 col-md col-lg ">
              {/* Verkochte Diensten Box */}
              <div className="midbox columnleft rounded-2 col-12 col-md p-3">
                <div className="d-flex flex-column align-items-start">
                  <h5 id="soldServices" className="heading-primary">
                    VERKOCHTE DIENSTEN
                  </h5>
                  <h5 id="soldServices" className="heading-primary">
                    IN{" "}
                    <span style={{ color: "#2884C5" }}>
                      {now.toUpperCase()}
                    </span>
                  </h5>
                </div>
                <div className="midBoxChartsLeft d-flex justify-content-center">
                  <MostSoldProductsChart />
                </div>
              </div>

              {/* Totaal vorige maand box */}
              <div className="smallbox rounded-2 col-12 col-md p-1 pe-5 text-center">
                <div className="d-flex flex-column align-items-center">
                  <p className="box-heading fw-bold mb-0">
                    TOTAAL VORIGE MAAND
                  </p>
                  <h4 className="box-value money-value-big mb-0">
                    € {checkIfIntegerAddComma(deals.totalPrice)}
                  </h4>
                </div>
                <div className="d-flex justify-content-between px-4">
                  <div>
                    <p className="label-small mb-0">DEALS</p>
                    <h5 className="value-small mb-0">{deals.totalDeals}</h5>
                  </div>
                  <div>
                    <p className="label-small mb-0">GEM. ORDER</p>
                    <h5 className="value-small mb-0">
                      € {checkIfIntegerAddComma(deals.averagePrice)}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md col-lg-6">
              {/* Team Target Box */}
              <div className="midbox rounded-2 col-12 col-md py-3 px-4 mb-3">
                <div className="text-center">
                  <h5 id="teamTarget" className="heading-primary">
                    TEAM TARGET VAN{" "}
                    <span style={{ color: "#2884C5" }}>
                      {now.toUpperCase()}
                    </span>
                  </h5>
                  <div className="d-flex justify-content-center mt-1">
                    <h4 className="box-heading teamTarget d-flex justify-content-center align-items-center afw-bold">
                      {teamTarget.text}
                    </h4>
                  </div>
                </div>
                <div className="h-auto w-100 d-flex align-items-center px-4">
                  <div>
                    <p className="box-heading value-display mb-1">BEDRAG</p>
                    <p className="box-heading money-value value-display mb-1">
                      {" "}
                      €{checkIfIntegerAddComma(amount)}
                    </p>
                  </div>
                  <SpeedoMeterBox />
                  <div>
                    <p className="box-heading value-display mb-1">TE GAAN</p>
                    <p className="box-heading to-go mb-1">
                      {" "}
                      €
                      {teamTarget.amount
                        ? checkIfIntegerAddComma(teamTarget.amount - totalPrice)
                        : 0}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column target-bigscreen">
                  <p className="box-heading value-display">TARGET</p>
                  <p className="box-heading value-display-target money-value">
                    €{teamTarget.amount}
                  </p>
                </div>
              </div>

              {/* Ranglijst Box */}
              <div className="box rounded-2 col-12 col-md p-1">
                <Plot
                  data={monthRankListBox}
                  layout={rankListBoxLayout}
                  config={{
                    displayModeBar: false,
                  }}
                  className="responsive-plot"
                  useResizeHandler={true}
                />
              </div>
              <div className="rounded-2 col-12 col-md py-3">
                <div className="d-flex flex-row justify-content-evenly">
                  {/* Left box*/}
                  <div className="smallboxes rounded-2 d-flex text-center flex-column">
                    <p className="box-heading bestSeller my-2">
                      BESTE VERKOPER AFGELOPEN MAAND
                    </p>
                    <h3 className="sellerofthemonth bestSeller mb-0">
                      {bestSeller.name}{" "}
                      <FontAwesomeIcon icon={faMedal} className="icon" />
                    </h3>
                  </div>

                  {/* Middle box */}
                  <div className="smallboxes dateBox rounded-2 d-flex text-center flex-column mx-4">
                    <div className="d-flex justify-content-center my-1">
                      <h2 className="hourMinute d-flex align-items-center justify-content-center">
                        {dayjs().format("HH")}
                      </h2>
                      <h2 className="hourMinute d-flex align-items-center justify-content-center">
                        {dayjs().format("mm")}
                      </h2>
                    </div>
                    <div className="px-3">
                      <h3>{dayjs().format("DD-MMM-YYYY")}</h3>
                    </div>
                  </div>

                  {/* Right box*/}
                  <div className="smallboxes rounded-2 d-flex text-center flex-column">
                    <div className="d-flex flex-column align-items-center">
                      <p className="box-heading fw-bold mt-2 mb-1">
                        TOTAAL BESTAANDE DEALS
                      </p>
                      <h4 className="box-value money-value mb-0">
                        € {checkIfIntegerAddComma(existantDeals.totalPrice)}
                      </h4>
                    </div>
                    <div className="d-flex justify-content-between px-4">
                      <div>
                        <p className="label-small mb-0">DEALS</p>
                        <h5 className="value-small mb-0">
                          {existantDeals.totalDeals}
                        </h5>
                      </div>
                      <div className="">
                        <p className="label-small mb-0">GEM. ORDER</p>
                        <h5 className="value-small mb-0">
                          € {checkIfIntegerAddComma(existantDeals.averagePrice)}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md col-lg">
              {/* Ranglijst 12 maanden Box */}
              <div className="biggestbox col-12 col-md col-lg rounded-2 p-3">
                <div className="d-flex flex-column align-items-start mb-4">
                  <h5 id="rankList" className="heading-primary">
                    RANGLIJST AFGELOPEN
                  </h5>
                  <h5 id="rankList" className="heading-primary color-blue">
                    12 MAANDEN
                  </h5>
                </div>
                <div className="midBoxCharts d-flex justify-content-center pb-1">
                  <RanklistChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5 d-flex justify-content-center">
          <div className="custom-spinner">
            <img
              className="img-fluid"
              src={Logo}
              alt="Loading"
              style={{ animation: "spin 0.7s linear infinite" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
